// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
@mixin button-standart($type) {
    @include rounded(50px);
    @include buttons();
    color: var(--toc-white);

    @if $type==primary {
        background: var(--toc-orange);

        &:hover {
            background: var(--toc-dark-orange);
        }
    }

    @else if $type==secondary {
        background: var(--toc-dark-orange);

        &:hover {
            background: var(--toc-brown);
            color: var(--toc-orange);
        }
    }

    @else if $type==tertiary {
        background: var(--toc-white);
        border: 2px solid var(--toc-orange);
        color: var(--toc-orange);

        &:hover {
            background: var(--toc-orange);
            color: var(--toc-white);
        }
    }

    @else if $type==quaternary {
        background: var(--toc-white);
        border: 2px solid var(--toc-white);
        color: var(--toc-brown);

        &:hover {
            background: var(--toc-white);
        }
    }

    @else if $type==default {
        background: var(--toc-white);
        border: 2px solid var(--toc-white);
        color: var(--toc-orange);

        &:hover {
            background: lighten($primary, 45%);
        }
    }
}

@mixin button-cta($type) {
    @include rounded(50px);
    @include buttons();
    color: var(--toc-white);

    @if $type==primary {
        background: var(--toc-blue);

        &:hover {
            background: var(--toc-dark-blue);
            color: var(--toc-white);
        }

        &:disabled {
            background: var(--toc-dark-blue);
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    @if $type==secondary {
        background: var(--toc-dark-blue);

        &:hover {
            background: #005c80;
            color: var(--toc-dark-blue);
        }
    }

    @if $type==tertiary {
        background: var(--toc-white);
        border: 2px solid var(--toc-blue);
        color: var(--toc-blue);
        text-shadow: none;

        &:hover {
            background: var(--toc-blue);
            color: var(--toc-white);
        }
    }

    @if $type==flash {
        background: var(--toc-flash);

        &:hover {
            background: var(--toc-flash-hover);
        }
    }

	@if $type==noug {
        background: var(--toc-noug);

        &:hover {
            background: var(--toc-noug-hover);
        }
    }

    @if $type==delete {
        background: var(--toc-light-red);

        &:hover {
            background: var(--toc-red);
        }
    }

    @if $type==disabled {
        background: var(--toc-light-grey);
    }

}

@mixin button-icon(){
    @include rounded(50px);
    @include buttons();

}

@mixin colors($background) {
    @include gradient(lighten($background, 13%), $background);

    border: solid 2px darken($background, 3%);
    color: mix(#fff, $background, 90%);

    &:hover {
        @include gradient(lighten($background, 10%), darken($background, 5%))
    }

    &:active {
        @include gradient($background, lighten($background, 10%));
        color: mix(#fff, $background, 70%);
    }
}

@mixin rounded($border) {
    -webkit-border-radius: $border;
    -moz-border-radius: $border;
    border-radius: $border;
}

@mixin shadow {
    box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, .2);
}

@mixin gradient($top, $bottom) {
    background-color: $bottom;
    background: -webkit-gradient(linear, left top, left bottom, from($top), to($bottom));
    background: -moz-linear-gradient(top, $top, $bottom);
}

@mixin buttons() {
    display: inline-block;
    padding: 1rem 3rem;
    text-align: center;
    font-size: 1.7rem;
    text-transform: uppercase;
    font-weight: bold;
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    &:active {
        position: relative;
        top: 2px;
    }
}