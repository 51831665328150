@import './variables';

/* ****************** MIXIN ****************** */
@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin flex-row-justify($justify) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: $justify;
}

@mixin content($content, $unicode) {
    @include flex-row-justify(space-between);
    font-size: 1.7rem;

    &::before {
        content: $content;
        text-transform: none;
    }

    &::after {
        @include icons($fontAwesomeFree, $unicode, initial, var(--toc-orange));
    }

    &.active {
        &::after {
            content: '\f078';
        }
    }
}

@mixin flex-column-justify($justify) {
    display: flex;
    flex-direction: column;
    justify-content: $justify;
}

@mixin icons($family, $content, $size, $color) {
    display: inline-flex;
    font-family: $family;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    content: $content;
    font-size: $size;
    color: $color;
    text-align: center;
}

@mixin icon-product-choose-store {
    color: var(--toc-light-brown);

    &::before {
        @include icons($fontAwesomeFree, '\f3c5', 1.5rem, inherit)
    }
}

@mixin icon-product-in-stock {
    color: var(--toc-green);

    &::before {
        @include icons($fontAwesomeFree, '\f058', 1.5rem, inherit)
    }
}

@mixin icon-product-supplier {
    color: var(--toc-blue);

    &::before {
        @include icons($fontAwesomeFree, '\f2f2', 1.5rem, inherit)
    }
}

@mixin icon-product-out-of-stock {
    color: var(--toc-red);

    &::before {
        @include icons($fontAwesomeFree, '\f057', 1.5rem, inherit)
    }
}

@mixin header-icons-text {
    font-size: 1.2rem;
    text-transform: uppercase;
}

@mixin color($color, $color-background) {
    color: var($color);
    background: var($color-background);
}

@mixin icon-toc($content) {
    height: 7rem;
    text-align: center;

    & a:not(.icon-shopping),
    & button:not(.icon-shopping) {
        text-decoration: none;
        color: var(--toc-white);

        &:hover {
            color: var(--toc-orange);
        }

        &::before {
            @include icons('Line Awesome Free', $content, 5rem, none);
            @include flex-column();
        }

        &>span {
            @include header-icons-text();
        }

        @media screen and (max-width: $breakpoint-sm) {
            &::before {
                font-size: 4rem;
            }
        }
    }
}

@mixin scrollbar() {

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 15px;
    }

    *::-webkit-scrollbar-track {
        background: #ffffff;
        border: 1px solid var(--toc-light-grey);
        border-radius: 10px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--toc-light-grey);
        border-radius: 10px;
    }
}

@mixin swiper() {
    margin-left: auto;
    margin-right: auto;
    padding-left: 2em;
    padding-right: 2em;
    position: relative;
    width: 92%;

    & button {
        border: none;
        background: none;
        color: var(--toc-orange);

        &.swiper-button-prev {
            left: 0;
        }

        &.swiper-button-next {
            right: 0;
        }
    }
}

@mixin productCard() {
    .card {
        border: none;

        &:hover {
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

            .card__body__add__compare {
                display: block;
            }
        }

        & * {
            background: var(--toc-white);
        }

        &__tag {
            position: absolute;
            left: -1.5rem;
            top: 0.5rem;
            padding: 0.5rem 2rem;
            text-transform: uppercase;
            font-weight: bold;
            color: var(--toc-white);
            background: var(--toc-red);
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                left: 0px;
                bottom: -15px;
                border-right: 1.5rem solid transparent;
                transform: rotate(180deg);
                border-bottom: 1.5rem solid #a10d16;
            }
        }

        &__header {
            &__add {
                &__compare {
                    background: transparent;
                    position: absolute;
                    top: 4.5rem;
                    right: 1rem;
                    z-index: 1;
                    label {
                        background: transparent;
                        &::after {
                            background: var(--toc-white);
                        }
                    }
                }

                &__wishlist {
                    &__button {
                        position: absolute;
                        top: 1rem;
                        right: 1rem;
                        padding: 0;
                        background: transparent;
                        border: none;
                        z-index: 1;

                        &__image {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 1rem;
                            width: 3rem;
                            height: 3rem;
                            border-radius: 50%;
                            border: 2px solid var(--toc-brown);

                            &:hover {
                                transform: scale(1.2);
                                transition: transform 0.1s;
                                color: var(--toc-orange);
                                border-color: currentColor;
                            }
                        }
                    }
                }
            }

            &__product {
                &:hover {
                    text-decoration: none;
                }

                &__image {
                    object-fit: contain;
                }

                &__title {
                    @include flex-column();
                    row-gap: 1rem;
                    min-height: 7rem;
                    text-align: center;
                    color: var(--toc-brown);

                    &__brand {
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-top: 2rem;
                        min-height: 1.8rem;
                    }

                    &__name {
                        display: -webkit-box;
                        height: 6.5rem;
                        font-weight: bold;
                        text-transform: initial;
                        overflow: hidden;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        &__body {
            &__availability {
                @include flex-column-justify(center);
                text-transform: uppercase;

                button {
                    text-transform: uppercase;
                }

                .stock-web {
                    color: var(--toc-brown);
                }

                .in-stock {
                    @include icon-product-in-stock();
                    @include flex-row-justify(center);
                    column-gap: 0.5rem;
                }

                .supplier {
                    @include icon-product-supplier();
                    @include flex-row-justify(center);
                    column-gap: 0.5rem;
                }

                .out-of-stock {
                    @include icon-product-out-of-stock();
                    @include flex-row-justify(center);
                    column-gap: 0.5rem;
                }
            }
        }

        &__footer {
            @include flex-row-justify(space-between);
            padding: 1rem 0;
            text-align: center;
            position: relative;

            .price-reduction {
                & mark {
                    color: var(--toc-red);
                }
            }

            & mark {
                font-size: 2rem;
                font-weight: bold;
                color: var(--toc-brown);
            }

            &__promotions {
                width: 5rem;
                position: relative;
                right: -1rem;
                padding: 0.5rem;
                font-weight: bold;
                background: var(--toc-red) !important;
                color: var(--toc-white);
                z-index: 1;

                &::after {
                    content: "";
                    position: absolute;
                    right: 0.1rem;
                    bottom: -8px;
                    border-right: 1rem solid transparent;
                    border-bottom: 1rem solid #a10d16;
                    border-left: 1rem solid transparent;
                    transform: rotate(316deg);
                }
            }
        }
    }
}

@mixin select() {
    select {
        width: 100%;
        padding: 0 2rem;
        margin-right: 1rem;
        height: 3.5rem;
        border-radius: 1rem;
        font-size: 1.3rem;
        line-height: 1.5;
        color: var(--toc-dark-grey);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        box-shadow: none;
        outline: none;
        border: none;

        &:nth-last-child(1) {
            margin: 0;
        }
    }
}

@mixin box() {
    padding: 3rem 2rem;
    background: var(--toc-grey);
    border-radius: 2.5rem;
}

@mixin link() {
    a, button {
        color: var(--toc-brown);
        border: none;
        background-color: transparent;

        &:hover {
            color: var(--toc-orange);
            text-decoration: underline;
        }
    }
}

@mixin boxInformations() {
    .box-bloc {
        @include flex-column();
        position: relative;
        row-gap: 1rem;
        padding: 3rem 2rem;
        background: var(--toc-grey);
        border-radius: 2.5rem;

        &-header {
            h2 {
                @include sectionTitles();
            }

            &--delete {
                //override mixin
                position: absolute;
                top: 0;
                right: 0;
                padding: 1rem;
                color: red;
                font-size: 2rem;
                background: none;
                text-shadow: none;
            }
        }

        &-content {
            text-transform: uppercase;
        }

        &-footer {
            @include flex-row-justify(space-between);

            &--update {
                @include button-cta(tertiary);
                padding: 0.5rem 1rem;
                font-size: 1.2rem;
                text-transform: initial;
            }
        }
    }
}

@mixin tables() {
    .tables {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

        thead {
            background: var(--toc-light-brown);
            color: var(--toc-white);

            tr {
                border-bottom: 4px solid #493932;

                th {
                    border: none;
                    height: 5rem;
                    vertical-align: middle !important;
                }
            }
        }

        tfoot {
            tr {
                &.totalprice {
                    height: 4rem;
                    text-transform: uppercase;
                    font-size: 2rem;
                    font-weight: bold;
                    color: var(--toc-white);
                    background: var(--toc-flash);
                }
            }
        }

        &__reference {
            span {
                font-weight: bold;
                color: var(--toc-brown)
            }

            a {
                font-weight: bold;
                color: var(--toc-brown)
            }
        }

        &__status {
            @include flex-row-justify(start);

            span {
                @include flex-row-justify(center);
                gap: 1rem;
                width: max-content;
                border-radius: 5rem;
                font-weight: bold;
                color: var(--toc-white);

                &:before {
                    content: '';
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background-color: inherit;
                    filter: brightness(0.5);
                }
            }
        }

        &__details {
            @include flex-row-justify(start);
            gap: 2rem;

            a {
                //override mixin
                padding: 0.5rem 1rem !important;
                font-size: 1.3rem !important;
                text-transform: capitalize !important;

                &.button-details {
                    @include button-cta(noug);
                    text-shadow: none;
                }

                &.button-reorder {
                    @include button-cta(flash);
                }

            }
        }

        &__price {
            &.tot {
                label {
                    font-weight: bold;
                    font-size: 1.5rem;
                    color: var(--toc-dark-orange);
                }
            }
        }
    }
}

@mixin timeline() {

    .timeline {
        position: relative;
        width: 660px;
        margin: auto;
        margin-top: 2rem;
        padding: 1rem 0;
        list-style-type: none;

        &::before {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            width: 0.5rem;
            height: 100%;
            background: var(--toc-brown);
            z-index: 5;
        }

        li {
            padding: 1rem 0;

            &::after {
                content: "";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
            }

            .direction {
                position: relative;
                width: 30rem;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -33px;
                    width: 1rem;
                    height: 1rem;
                    background: var(--toc-white);
                    border-radius: 1rem;
                    border: 0.5rem solid var(--toc-orange);
                    z-index: 10;
                }
            }

            .direction-l {
                float: left;
                text-align: right;

                &::before {
                    right: -38px;
                    left: auto;
                }

                .flag-wrapper {
                    flex-direction: row-reverse;

                    .flag {
                        &::after {
                            left: 100%;
                            border-right-color: transparent;
                            border-left-color: inherit;
                        }
                    }
                }
            }

            .direction-r {
                float: right;
            }

            .flag-wrapper {
                display: flex;
                position: relative;
                text-align: center;

                .flag {
                    position: relative;
                    display: inline;
                    padding: 0.5rem 1rem;
                    border-radius: 0.5rem;
                    font-weight: 600;
                    text-align: left;
                    color: var(--toc-white);
                    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);

                    &::after {
                        content: "";
                        position: absolute;
                        right: 100%;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 0;
                        width: 0;
                        border: solid transparent;
                        border-right-color: inherit;
                        border-width: 8px;
                        pointer-events: none;
                    }
                }

                .time-wrapper {
                    display: inline;
                    position: relative;
                    top: -1rem;
                    font-size: 1.1rem;
                    color: var(--toc-red);
                    vertical-align: middle;

                    .time {
                        display: inline-block;
                        padding: 0.5rem;
                        background: var(--toc-white);
                    }
                }
            }
        }

        @media screen and (max-width: $breakpoint-md) {
            width: auto;

            &::before {
                left: 2rem;
            }

            li {

                .direction {
                    float: none !important;
                    left: 5rem;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -33px;
                        width: 1rem;
                        height: 1rem;
                        background: var(--toc-white);
                        border-radius: 1rem;
                        border: 0.5rem solid var(--toc-orange);
                        z-index: 10;
                    }
                }

                .direction-l {
                    &::before {
                        right: inherit;
                    }

                    .flag-wrapper {
                        flex-direction: row;

                        .flag {
                            &::after {
                                left: inherit;
                                border-right-color: inherit;
                                border-left-color: initial;
                            }
                        }
                    }
                }

                .flag-wrapper {
                    display: flex;
                    position: relative;
                    text-align: center;

                    .flag {
                        position: relative;
                        display: inline;
                        padding: 0.5rem 1rem;
                        border-radius: 0.5rem;
                        font-weight: 600;
                        text-align: left;
                        color: var(--toc-white);
                        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);

                        &::after {
                            content: "";
                            position: absolute;
                            right: 100%;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 0;
                            width: 0;
                            border: solid transparent;
                            border-right-color: inherit;
                            border-width: 8px;
                            pointer-events: none;
                        }
                    }

                    .time-wrapper {
                        display: inline;
                        position: relative;
                        top: -1rem;
                        font-size: 1.1rem;
                        color: var(--toc-red);
                        vertical-align: middle;

                        .time {
                            display: inline-block;
                            padding: 0.5rem;
                            background: var(--toc-white);
                        }
                    }
                }
            }
        }
    }
}

@mixin keyframes($name: "default", $duration: 0.65s) {
    @keyframes click-wave {
        0% {
            height: 20px;
            width: 20px;
            opacity: 0.35;
            position: relative;
        }

        100% {
            height: 100px;
            width: 100px;
            margin-left: -40px;
            margin-top: -40px;
            opacity: 0;
        }
    }

    @keyframes animS {
        100% {
            transform: translateX(140px);
        }
    }

    animation-name: $name;
    animation-duration: $duration;
}

@mixin loader() {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(#35180C, .2);
        border-radius: 1.7rem;
        backdrop-filter: blur(2px);
        z-index: 2;
    }

    &::after {
        position: absolute;
        inset: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        content: '\f1ce';
        font-family: 'Line Awesome Free';
        font-weight: bold;
        font-size: 5rem;
        animation: la-spin 2s infinite linear;
        color: var(--toc-orange);

        z-index: 3;
    }
}
