@import '../../../abstracts/_mixins';
@import '../../../abstracts/_variables';
@import '../../../components/title';
@import '../../../components/button';

#bestSellersHome {
    flex-direction: column;

    @media (min-width : 1200px) {
        .container {
            max-width: 1620px;
        }
    }
}

.swiper__product__home-js {
    display: flex;
    justify-content: center;
}

#afineoBestSellers {
    flex-wrap: nowrap;
    margin: 0;
    @media (min-width : 1200px) {
        margin-left: 0;
    }
}

.afineo {
    @media (max-width: $breakpoint-md){
        margin-top: 2rem;
    }

    &-title {
        @include title();
    }

    &-button {
        border: none;
        background: none;
        color: var(--toc-orange) !important;
        z-index: 100;

        &.swiper-button-prev {
            left: -5%;
        }

        &.swiper-button-next {
            right: -5%;
        }
    }

    &-link {
        @include flex-row-justify(center);
        margin: 2rem 0;

        & a {
            @include button-cta(primary);
        }
    }

    &__best-sales {
        @include productCard();
        position: relative;

        &__swiper {
            &__list {
                display: block;
                justify-content: center;
                margin-top: 2rem
            }
        }
    }

    &__brand {
        position: relative;
        margin-top: 5rem;

        &__swiper {
            &__images {
                display: block;
                margin: auto;
                position: relative;
                height: auto;
                width: 18rem;
                height: 18rem;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-color: var(--toc-white);
            }
        }
    }

    @media (max-width : $breakpoint-xxl) {
        &-button {
            &.swiper-button-prev {
                left: 0;
                z-index: 100;
            }

            &.swiper-button-next {
                right: 0;
                z-index: 100;
            }
        }
    }
}